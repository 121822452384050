import { ILeaderboard, IUserGame } from "../interfaces/IGameInterfaces";


type Props = {
    gameInfo: IUserGame,
    leaderboard: ILeaderboard[]
  }

export const Leaderboard = ({gameInfo, leaderboard} : Props) => {

    const board: JSX.Element[] = [];

    if (leaderboard !== undefined && leaderboard !== null) {
        for (var i = 0; i < leaderboard.length; i++) {
            board.push(<li key={i} className="list-group-item">{leaderboard[i].name} ({leaderboard[i].points} pts)</li>);
        }

    return (
        <div className="mt-5 card text-start" id="leaderboard">
            <h5 className="card-header bg-success">LEADERBOARD - {gameInfo.gameInfo.name}</h5>
            <div className="card-body">
                <ul className="list-group list-group-flush list-group-numbered">
                {board}
                </ul>
            </div>
        </div>
    )
    } else {
        return null;
    }
}