import React, { useEffect, useState } from 'react';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
//import { b2cPolicies } from "./authConfig";
import { Home } from "./components/home";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "./lib/appInsights";
import { Route, Routes } from "react-router-dom";
import { Privacy } from "./components/privacy";
import "./App.css";
import { Help } from './components/help';

type AppProps = {
    msalInstance: IPublicClientApplication
};

function App({msalInstance}: AppProps) {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const [colorScheme, setColorScheme] = useState(matcher.matches ? 'dark' : 'light');

    useEffect(() => {
        const onChange = ({ matches }) => {
          setColorScheme(matches ? 'dark' : 'light');
        };
    
        matcher.addEventListener('change', onChange);
    
        return () => {
          matcher.removeEventListener('change', onChange);
        };
      }, [matcher]);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalProvider instance={msalInstance}>
                <div className={(colorScheme === "dark" ? "nightmode" : "")}>
                    <Routes>
                        <Route path="/" element={<Home colorScheme={colorScheme} appInsights={appInsights} showHelp={false} />} />
                        <Route path="/privacy" element={<Privacy colorScheme={colorScheme} />} />
                        <Route path="/help" element={<Home colorScheme={colorScheme} appInsights={appInsights} showHelp={true} />} />
                    </Routes>
                </div>
            </MsalProvider>
        </AppInsightsContext.Provider>
            );
}

export default App;