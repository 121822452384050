import classnames from 'classnames'
import { Button } from "react-bootstrap";
import { CharStatus } from '../../interfaces/IGameInterfaces'
import { REVEAL_TIME_MS } from '../../constants/settings'

interface Props {
  children?: React.ReactChild | React.ReactChild[]
  status?: CharStatus | null
  width?: number
  value: string
  onClick: Function
  isRevealing?: boolean
  gridSize: number
}

export const Key = ({children,status,width = 43,value,onClick, isRevealing, gridSize}: Props) => {
  const classes = classnames(
    '',
    {
      'btn-info': status === CharStatus.unknown,
      'btn-secondary': status === CharStatus.absent,
      'btn-success': status === CharStatus.correct,
      'btn-warning': status === CharStatus.present
    }
  )
  const keyDelayMs = REVEAL_TIME_MS * gridSize

  const ww = (width === 43) ? " key-standard" : " key-wide";

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    height: '58px',
  }

  return (
    <Button variant=""
      style={styles}
      className={classes + ww}
      
      onClick={() => onClick(value)}
    >
      {children || value}
    </Button>
  )
}

