export interface IGameInfo {
    id: string;
    name?: string;
    sponsorHTML?: string;
    starttime: Date;
    endtime: Date;
}

export interface IGameCompletion {
    gameInfo: IGameInfo;
    correctWords: string[];
    nextGameInfo?: IGameInfo;
    leaderboard: ILeaderboard[];
}

export interface ILeaderboard {
    name: string;
    points: number;
}

export interface IUserGameLink {
    id: string;
    started: Date;
}

export interface IUser {
    id: string;
    name: string;
    lastLogin: Date;
    banned: boolean;
    currentGame: IUserGame;
    previousGames: IUserGameLink[];
}

export interface IUserGame {
    id: string;
    userId: string;
    gameInfo: IGameInfo;
    started: Date;
    completed?: Date;
    complete: boolean;
    currentBoard: number;
    boards: IBoardResult[];
    gameLedger: IPointLedgerItem[];
    points: number;
    isRevealing?: boolean;
}

export interface IBoardResult {
    id: string;
    size: number;
    active: boolean;
    complete: boolean;
    found: boolean;
    hardMode: boolean;
    currentLine: number;
    guesses: IBoardGuess[];
    allCharStatuses: ICharacterStatus[];
    pointsLedger: IPointLedgerItem[];
    points: number;
}

export interface ICharacterStatus {
    character: string;
    status: CharStatus;
}

export interface IBoardGuess {
    guess: string;
    charStatuses: CharStatus[];
}

export enum CharStatus {
    unknown = 0,
    absent,
    present,
    correct
}

export enum PointLedgerItemType {
    RowPoints = 0,
    BoardPoints,
    BoardMultiplier,
    GameMultiplier
}

export interface IPointLedgerItem {
    type: PointLedgerItemType;
    description: string;
    row?: number;
    value: number;
}