import { IUserGame, IBoardGuess, CharStatus, IGameCompletion } from "../interfaces/IGameInterfaces";
import { useEffect, useState } from "react";
import { getAPI } from "../lib/api";
import { FinalRow } from "./grid/FinalRow";
import Countdown from 'react-countdown';
import { NEXT_GAME_TEXT } from "../constants/messages";
import { Leaderboard } from "./leaderboard";
import { Icon } from '@fluentui/react/lib/Icon';
import { AdGameComplete } from './ads/AdGameComplete'

type Props = {
    gameInfo?: IUserGame | null | undefined
    token: string
    setUser: any
  }
  

export const GameComplete = ({gameInfo, token, setUser} : Props) => {

    const [gameCompletion, setGameCompletion] = useState<IGameCompletion | null>(null);
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [showNextGame, setShowNextGame] = useState(false);
    const [shareText, setShareText] = useState('');

    const [final, setFinal] = useState<JSX.Element[]>([]);

    const [timer, setTimer] = useState<JSX.Element[]>([]);

    function nextGame() {
        getAPI('games/nextgame', token, (response) => {
            if (response) {
                setUser(response);
            }}, (error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        if ((gameCompletion === undefined || gameCompletion === null)  && (token))
        {
            getAPI('games/complete', token, (response) => {
                if (response) {
                    setGameCompletion(response);
                }}, (error) => {
                    console.log(error);
                });
           
        }

        if (gameCompletion !== undefined && gameCompletion !== null)
        {
            if (gameCompletion.leaderboard.length > 0) {
                setShowLeaderboard(true);
            }

            if (new Date(gameCompletion!.nextGameInfo!.starttime) <= new Date()) {
                setShowNextGame(true);
            }

            if (gameCompletion!.nextGameInfo !== undefined && gameCompletion!.nextGameInfo !== null) {
                const tempTimer: JSX.Element[] = [];

                tempTimer.push(<Countdown key={1} date={new Date(gameCompletion!.nextGameInfo!.starttime)} onComplete={() => setShowNextGame(true) } renderer={renderer} />);

                setTimer(tempTimer);
            }
            const temp: JSX.Element[] = [];
            var shareInfo: string = 'Words 5X\n' + gameCompletion.gameInfo.name + '\n' + gameInfo?.points + ' points\n\n';

            for (var i = 0; i < 5; i++) {
                
                var guess: IBoardGuess = {
                    guess: gameCompletion.correctWords[i],
                    charStatuses: Array(gameCompletion.correctWords[i].length).fill(CharStatus.absent)
                }

                
                for (var j = 0; j < gameCompletion.correctWords[i].length; j++)
                {
                    var item = gameInfo?.boards[i]?.guesses[j];
                    if (item) {

                        if (typeof item.guess!='undefined' && item.guess)
                        {
                            if (item.guess === gameCompletion.correctWords[i].toUpperCase())
                            {
                                guess.charStatuses[j] = CharStatus.correct;
                                shareInfo += "🟩";
                            } else {
                                guess.charStatuses[j] = CharStatus.present;
                                shareInfo += "🟨";
                            }
                        } else {
                            guess.charStatuses[j] = CharStatus.absent;
                            shareInfo += "⬛";
                        }
                    }

                };

                shareInfo += '\n';

                temp.push(<FinalRow key={i} guess={guess} points={gameInfo?.boards[i]?.points ?? 0} fill={true} />);
            }
            setShareText(shareInfo);
            setFinal(temp);
        }
    }, [gameCompletion]);

    // Random component
    const Completionist = () => <button id="nextgame" className="btn btn-primary" onClick={() => nextGame()}>Start Next Game!</button>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <span className="fs-1 fw-bold">{hours.toString().padStart(2,'0')}:{minutes.toString().padStart(2,'0')}:{seconds.toString().padStart(2,'0')}</span>;
    }
    };

    const shareClick = async () => {
        if (window.navigator.share) {
          try {
            await window.navigator.share({
                'title': gameCompletion?.gameInfo.name,
                'text' : shareText,
                'url': 'https://www.words5x.com'
                });
          } catch (e) {
            console.warn(e);
          }
        } else {
            navigator.clipboard.writeText(shareText + '\nhttps://www.words5x.com');
        }
      };

      const clipClick = async () => {
          navigator.clipboard.writeText(shareText + '\nhttps://www.words5x.com');
      };

    return (
        <div className="container">
            <p className="h3 mt-4">GAME COMPLETE</p>
            <p>The number of guesses for each word are shown using the color along the bottom. Yellow is incorrect, Green is correct.</p>
            <p>Here are the answers:</p>
            {final}
            <div className="row mt-4">
                <div className="col text-center">
                    <div>Total Points:</div>
                    <div className="h2">{gameInfo?.points}</div>
                    <div><button className="btn btn-primary mt-1" onClick={shareClick}><Icon iconName="Share" /> Share</button> <button className="btn btn-primary mt-1" onClick={clipClick}><Icon iconName="Copy" /> Copy to Clipboard</button></div>
                    <div><button className="btn btn-primary mt-1" onClick={() => window.location.href="#leaderboard"}>View Leaderboard</button></div>
                    {(!showNextGame) && <div className="mt-4">
                        {NEXT_GAME_TEXT}<br />
                        {timer}
                    </div>}
                    {(showNextGame) && <div className="mt-4">
                        <button id="nextgame" className="btn btn-primary" onClick={() => nextGame()}>Start Next Game!</button>
                    </div>}
                    {(showLeaderboard) && <Leaderboard gameInfo={gameInfo!} leaderboard={gameCompletion!.leaderboard} />}
                </div>
            </div>
        </div>
    )
}