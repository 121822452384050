import { FinalCell } from './FinalCell'
import { IBoardGuess } from '../../interfaces/IGameInterfaces'

type Props = {
  guess: IBoardGuess,
  points: number,
  fill?: boolean
}

export const FinalRow = ({ guess, points, fill = false }: Props) => {

  const filler : JSX.Element[] = [];

  if (fill) {
    for (var i = 0; i < 8 - guess.guess.length; i++) {
      filler.push(<div key={i} className="col font-weight-bolder text-uppercase grid-cell-small lh-cell-small me-1 text-center"></div>)
    }
  }

  return (
    <div className="row mb-1 justify-content-center row-completed">
      <div className="col points-cell-small lh-cell-small me-1">{ points > 0 ? "+" + points : ""}</div>
      {guess.guess?.split('').map((letter, i) => (
        <FinalCell key={i} value={letter} status={guess.charStatuses[i]} small={true} />
      ))}
      {filler}
      <div className="col points-cell-small lh-cell-small"></div>
    </div>
  )
}
