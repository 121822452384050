import { Key } from './Key'
import { useEffect } from 'react'
import { CharStatus, ICharacterStatus, IBoardResult } from '../../interfaces/IGameInterfaces'

type Props = {
  onChar: Function
  onDelete: Function
  onEnter: Function
  result?: IBoardResult | null | undefined
  isRevealing?: boolean
  gridSize: number
}

export const Keyboard = ({ onChar, onDelete, onEnter, result, isRevealing, gridSize } : Props) => {
  const baseStatuses: ICharacterStatus[] = [
  { "character": 'Q', status: CharStatus.unknown },
  { "character": 'W', status: CharStatus.unknown },
  { "character": 'E', status: CharStatus.unknown },
  { "character": 'R', status: CharStatus.unknown },
  { "character": 'T', status: CharStatus.unknown },
  { "character": 'Y', status: CharStatus.unknown },
  { "character": 'U', status: CharStatus.unknown },
  { "character": 'I', status: CharStatus.unknown },
  { "character": 'O', status: CharStatus.unknown },
  { "character": 'P', status: CharStatus.unknown },
  { "character": 'A', status: CharStatus.unknown },
  { "character": 'S', status: CharStatus.unknown },
  { "character": 'D', status: CharStatus.unknown },
  { "character": 'F', status: CharStatus.unknown },
  { "character": 'G', status: CharStatus.unknown },
  { "character": 'H', status: CharStatus.unknown },
  { "character": 'J', status: CharStatus.unknown },
  { "character": 'K', status: CharStatus.unknown },
  { "character": 'L', status: CharStatus.unknown },
  { "character": 'Z', status: CharStatus.unknown },
  { "character": 'X', status: CharStatus.unknown },
  { "character": 'C', status: CharStatus.unknown },
  { "character": 'V', status: CharStatus.unknown },
  { "character": 'B', status: CharStatus.unknown },
  { "character": 'N', status: CharStatus.unknown },
  { "character": 'M', status: CharStatus.unknown }
  ];

  const charStatuses = (!result) ? baseStatuses : result.allCharStatuses;

  const onClick = (value) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        const key = e.key.toUpperCase()
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div className="mb-3 fixed-bottom">
      <div className="d-flex justify-content-center keyboard-row">
        <Key value="Q" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'Q'; })?.status} />
        <Key value="W" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'W'; })?.status} />
        <Key value="E" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'E'; })?.status} />
        <Key value="R" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'R'; })?.status} />
        <Key value="T" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'T'; })?.status} />
        <Key value="Y" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'Y'; })?.status} />
        <Key value="U" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'U'; })?.status} />
        <Key value="I" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'I'; })?.status} />
        <Key value="O" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'O'; })?.status} />
        <Key value="P" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'P'; })?.status} />
      </div>
      <div className="d-flex justify-content-center keyboard-row">
        <Key value="A" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'A'; })?.status} />
        <Key value="S" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'S'; })?.status} />
        <Key value="D" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'D'; })?.status} />
        <Key value="F" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'F'; })?.status} />
        <Key value="G" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'G'; })?.status} />
        <Key value="H" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'H'; })?.status} />
        <Key value="J" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'J'; })?.status} />
        <Key value="K" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'K'; })?.status} />
        <Key value="L" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'L'; })?.status} />
      </div>
      <div className="d-flex justify-content-center">
        <Key width={65.4} value="ENTER" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={CharStatus.unknown}>
          ENTER
        </Key>
        <Key value="Z" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'Z'; })?.status} />
        <Key value="X" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'X'; })?.status} />
        <Key value="C" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'C'; })?.status} />
        <Key value="V" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'V'; })?.status} />
        <Key value="B" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'B'; })?.status} />
        <Key value="N" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'N'; })?.status} />
        <Key value="M" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={charStatuses.find(function (item) { return item.character === 'M'; })?.status} />
        <Key width={65.4} value="DELETE" onClick={onClick} gridSize={gridSize} isRevealing={isRevealing} status={CharStatus.unknown}>
          <i className="fa fa-backspace"></i>
        </Key>
      </div>
    </div>
  )
}
