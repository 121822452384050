import classnames from 'classnames'
import { CharStatus } from '../../interfaces/IGameInterfaces'
import { REVEAL_TIME_MS } from "../../constants/settings";

type Props = {
  value?: string
  status?: CharStatus
  small: boolean
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
  maxposition?: number
  isCurrent?: boolean
}


export const Cell = ({ value, status, small, isRevealing, isCompleted, position=0, maxposition=0, isCurrent = false }: Props) => {
  const isFilled = value && !isCompleted;
  const shouldReveal = isRevealing && isCompleted;

  const animationClass = (maxposition > 0 ? ' delay' + position + '-' + maxposition : ' delay'+ position);

  const current = isCurrent ? " current": "";
  const size = small ? "grid-cell-small": "grid-cell";
  const classes = classnames(
    'font-weight-bolder text-uppercase text-center ' + size + current + animationClass,
    {
      'border border-secondary border-2': status === CharStatus.unknown,
      'absent reveal-border': shouldReveal && status === CharStatus.absent,
      'correct reveal-border': shouldReveal && status === CharStatus.correct,
      'present reveal-border': shouldReveal && status === CharStatus.present,
      'absent bg-secondary text-white': !shouldReveal && status === CharStatus.absent,
      'correct bg-success text-white': !shouldReveal && status === CharStatus.correct,
      'present bg-warning text-white': !shouldReveal && status === CharStatus.present,
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal
    }
  )

  return (
      <div className={classes}>
        <div className={"letter-container delay" + position}>
          {value}
        </div>
      </div>
  )
}
