import { apiConfig } from "../authConfig";
/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function getAPI(endpoint: string, accessToken: string, success: Function, failure: Function) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(apiConfig.webApiUri + endpoint, options)
        .then(response => {
            if (!response.ok) { 
                throw Error(response.status.toString());
            }
            return response.json()
        })
        .then(result => {
            success(result);
        })
        .catch(error => { 
            failure(error);
        });
}

export async function postAPI(endpoint: string, accessToken: string, data: any, success: Function, failure: Function) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers,
        body: data
    };

    return fetch(apiConfig.webApiUri + endpoint, options)
        .then(response => {
            if (!response.ok) { 
                throw Error(response.status.toString());
            }
            return response.json()
        })
        .then(result => {
            success(result);
        })
        .catch(error => { 
            failure(error);
        });

}