import classnames from 'classnames'
import { CharStatus } from '../../interfaces/IGameInterfaces'

type Props = {
  value?: string
  status?: CharStatus
  small: boolean
}

export const FinalCell = ({ value, status, small }: Props) => {
  const size = small ? " grid-cell-small lh-cell-small": " grid-cell lh-cell";
  const classes = classnames(
    'col border-bottom border-5 font-weight-bolder text-uppercase bg-secondary' + size + ' me-1 text-center',
    {
      'border-secondary': status === CharStatus.unknown,
      'text-white border-secondary': status === CharStatus.absent,
      'text-white border-success': status === CharStatus.correct,
      'text-white border-warning': status === CharStatus.present,
    }
  )

  return (
    <>
      <div className={classes}>{value}</div>
    </>
  )
}
