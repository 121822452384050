import { CharStatus } from '../../interfaces/IGameInterfaces'
import { Cell } from './Cell'

type Props = {
  guess: string
  gridSize: number
  currentRowClass: string
  isRevealing?: boolean
}

export const CurrentRow = ({ guess, gridSize, currentRowClass, isRevealing }: Props) => {
  const splitGuess = guess.split('')
  const emptyCells = Array.from(Array(gridSize - splitGuess.length))
  const classes = `justify-content-center row-current ${currentRowClass}`

  if (isRevealing)
  {
      return (
        <div className={classes}>
          {splitGuess.map((letter, i) => (
            <Cell key={i} value={letter} position={gridSize - 1} status={CharStatus.unknown} small={true} isRevealing={isRevealing} isCurrent={true} isCompleted={true} />
          ))}
          {emptyCells.map((_, i) => (
            <Cell key={i} status={CharStatus.unknown} position={gridSize - 1} small={true} isRevealing={isRevealing} isCurrent={true} isCompleted={true} />
          ))}
        </div>
      )
  } else {
      return (
        <div className={classes}>
          {splitGuess.map((letter, i) => (
            <Cell key={i} value={letter} status={CharStatus.unknown} small={false} />
          ))}
          {emptyCells.map((_, i) => (
            <Cell key={i} status={CharStatus.unknown} small={false} />
          ))}
        </div>
      )
  }
}
