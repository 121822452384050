import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import { IUserGame, PointLedgerItemType } from '../../interfaces/IGameInterfaces'

type Props = {
  gameInfo?: IUserGame | null | undefined
  currentGuess: string
  currentRowClass: string
}

export const Grid = ({ gameInfo, currentGuess, currentRowClass }: Props) => {
  const isRevealing = gameInfo?.isRevealing;
  var guesses = gameInfo?.boards[gameInfo?.currentBoard]?.guesses ?? null;
  var gridSize = gameInfo?.boards[gameInfo?.currentBoard]?.size ?? 4;
  var points = gameInfo?.boards[gameInfo?.currentBoard]?.pointsLedger.filter(function(item) {
    return item.type === PointLedgerItemType.RowPoints;
  }) ?? [];

  function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      var key = grouper(item)
      if (!store.has(key)) {
        store.set(key, [item])
      } else {
        store.get(key)?.push(item)
      }
      return store
    }, new Map<K, V[]>())
  }

  var rowPoints = groupBy(points, x => x.row);

  var done = gameInfo?.boards[gameInfo?.currentBoard]?.complete ?? false;

  var filled : number | undefined = (guesses?.filter(function(item) { 
    return (item.guess !== 'undefined' && item.guess);
  } ).length ) ?? 0;

  const empties = filled < gridSize ? Array.from(Array(gridSize - (filled) - (done ? 0 : 1))) : []
  const completed = guesses?.filter(function (item) {
    return (item.guess !== 'undefined' && item.guess);
  }) ?? [];

    return (
    <div className="container mt-4 mb-4">
      {completed.map((guess, i) => (
        <CompletedRow key={i} guess={guess} points={rowPoints.get(i)?.reduce((total, item) => item.value + total, 0) ?? 0} isRevealing={isRevealing && completed!.length - 1 === i} />
      ))}
      {((filled < gridSize) && (!done)) && <CurrentRow guess={currentGuess} gridSize={gridSize} currentRowClass={currentRowClass} isRevealing={isRevealing} />}
      {empties.map(function (_, i) {
        return (
        <EmptyRow gridSize={gridSize} key={i} points={rowPoints.get(i + completed.length + ((((filled ?? 0) < gridSize) && (!done)) ? 1 : 0))?.reduce((total, item) => item.value + total, 0) ?? 0} />
        )
      })}
    </div>
  )
}
