import { CharStatus } from '../../interfaces/IGameInterfaces'
import { Cell } from './Cell'

type Props = {
  gridSize: number,
  points: number
}

export const EmptyRow = ({gridSize, points}: Props) => {
  const emptyCells = Array.from(Array(gridSize))

  return (
    <div className="justify-content-center row-empty">
      <div className="points-cell-small"></div>
      {emptyCells.map((_, i) => (
        <Cell key={i} status={CharStatus.unknown} small={true} />
      ))}
      <div className="points-cell-small">{ points > 0 ? "+" + points : ""}</div>
    </div>
  )
}
