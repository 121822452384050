import { IUserGame } from "../interfaces/IGameInterfaces";

interface Props {
    gameInfo: IUserGame | null | undefined
}

export const BoardSteps = (props: Props) => {

    const final : JSX.Element[] = [];

        for (var i = 0; i < 5; i++) {
            var active = i === props.gameInfo?.currentBoard ?? 0 ? " border border-success border-2 ms-1" : (i < (props.gameInfo?.currentBoard ?? 0) ? " step-complete ms-1" : " step-complete ms-1");
            final.push(<div key={i} className={"col " + active}><div className="w-100 text-center fw-bold">{i+4}</div><div className="w-100 text-center">{props.gameInfo?.boards[i].points ?? 0}<br />pts</div></div>)
        }

        return (
            <div className="container">
                <div className="row mt-2 mb-2">
                    <div className="col h3">{props.gameInfo?.gameInfo.name}</div>
                </div>
                <div className="row">
                    {final}
                    <div className="col step-total ms-1 me-1"><div className="w-100 text-center fw-bold">Total</div><div className="w-100 text-center">{props.gameInfo?.points ?? 0}<br />pts</div></div>
                </div>
            </div>
        )
}