import { Cell } from "./grid/Cell";
import { CharStatus } from "../interfaces/IGameInterfaces";

export const Help = (props) => {

        return (
            <div className="container">
            <p className="h3 mt-4">HOW TO PLAY</p>
            <p>Each daily game consists of 5 word puzzle boards, from 4 to 8 characters long.</p>
            <p>Guess the <strong>WORD</strong> five times, starting with a 4-letter word and ending with an 8-letter word.</p>
            <p>Each guess must be a valid word for the current board's size. Hit the enter button to submit.</p>
            <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
            <hr />
                <p><strong>Examples</strong></p>
                <div className="container">
                    <div className="row-completed">
                        <Cell value="V" status={CharStatus.correct} small={false} />
                        <Cell value="e" status={CharStatus.unknown} small={false} />
                        <Cell value="r" status={CharStatus.unknown} small={false} />
                        <Cell value="y" status={CharStatus.unknown} small={false} />
                    </div>
                    <div className="row">
                        <p className="ps-0 mt-1">The letter <strong>V</strong> is in the word and in the correct spot.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row-completed">
                        <Cell value="H" status={CharStatus.unknown} small={false} />
                        <Cell value="a" status={CharStatus.present} small={false} />
                        <Cell value="p" status={CharStatus.unknown} small={false} />
                        <Cell value="p" status={CharStatus.unknown} small={false} />
                        <Cell value="y" status={CharStatus.unknown} small={false} />
                    </div>
                    <div className="row">
                        <p className="ps-0 mt-1">The letter <strong>A</strong> is in the word but in the wrong spot.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row-completed">
                        <Cell value="a" status={CharStatus.unknown} small={false} />
                        <Cell value="l" status={CharStatus.unknown} small={false} />
                        <Cell value="w" status={CharStatus.unknown} small={false} />
                        <Cell value="a" status={CharStatus.unknown} small={false} />
                        <Cell value="y" status={CharStatus.absent} small={false} />
                        <Cell value="s" status={CharStatus.unknown} small={false} />
                    </div>
                    <div className="row">
                        <p className="ps-0 mt-1">The letter <strong>Y</strong> is not in the word in any spot.</p>
                    </div>
                </div>
            <p>Points can be earned in the following ways:</p>
            <ul>
                <li>Finding a letter in the wrong spot</li>
                <li>Finding a letter in the correct spot</li>
                <li>NOTE: the above points are only awarded when the letter is first found. In other words, if you find a letter in the wrong spot you'll get points but you <b>will not</b> get points when (if) you ultimately find the correct spot.</li>
                <li>When (if) you find the correct word (i.e. 4 points for 4-letter word, etc.)</li>
                <li>For each unused guess</li>
            </ul>
            <p>The smaller the word, the more points each of the above point-generating events is worth. Smaller words are harder!</p>
            <p>Share your results with friends and check the leaderboards when you complete each game!</p>
            <p><strong>A new set of game boards will be available each day!</strong></p>
            <p>We respect your privacy! View our <a href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
        </div>
        )
}