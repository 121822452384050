import { Cell } from './Cell'
import { IBoardGuess } from '../../interfaces/IGameInterfaces'


type Props = {
  guess: IBoardGuess
  points: number
  fill?: boolean
  isRevealing?: boolean
}

export const CompletedRow = ({ guess, points, fill = false, isRevealing }: Props) => {

  const filler : JSX.Element[] = [];

  if (fill) {
    for (var i = 0; i < 8 - guess.guess.length; i++) {
      filler.push(<div key={i} className="col font-weight-bolder text-uppercase grid-cell-small lh-cell-small me-1 text-center"></div>)
    }
  }

  return (
    <div className={"justify-content-center row-completed" + (isRevealing ? " row-reveal" : "")}>
      {(isRevealing) && (<div className={"points-cell-small lh-cell-small reveal-points delay" + guess.guess.length}></div>)}
      {(!isRevealing) && (<div className="points-cell-small lh-cell-small"></div>)}
      {filler}
      {guess.guess?.split('').map((letter, i) => (
        <Cell 
          key={i} 
          value={letter} 
          status={guess.charStatuses[i]} 
          small={!isRevealing ?? true} 
          position={i} 
          maxposition={guess.guess.length - 1}
          isRevealing={isRevealing} 
          isCompleted={true} 
        />
      ))}
      {(isRevealing) && (<div className={"points-cell-small lh-cell-small reveal-points delay" + guess.guess.length}>{ points > 0 ? "+" + points : ""}</div>)}
      {(!isRevealing) && (<div className="points-cell-small lh-cell-small">{ points > 0 ? "+" + points : ""}</div>)}
    </div>
  )
}
