// AppInsights.js
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a6215467-3b3c-4930-80ab-21280fd19d60',
        disableFetchTracking: false,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableCorsCorrelation: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.setAuthenticatedUserContext()
export { reactPlugin, appInsights };