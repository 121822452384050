import React from 'react';

export const Privacy = (props) => {
    return (
      <div className="main-container p-4">
        <h1>Privacy Policy for Words 5X</h1>

        <p>
          Words 5X ("the game") is published by Mod Digital LLC ("Mod Digital"), a Delaware Limited Liability
          Company. Company information can be found on <a href="https://mod.digital" target="_blank" rel="noreferrer">https://mod.digital</a>. This privacy policy will explain how our organization uses the
          personal data we collect from you when you use the game.
        </p>

        <h3>What data do we collect?</h3>
        <p>Mod Digital collects the following data:</p>
        <ul>
          <li>
            Personal identification information:
            <ul>
                <li>Email address</li>
                <li>Display Name - customizable by user</li>
                <li>Social Account (optional) - used to authenticate the user</li>
            </ul>
          </li>
          <li>Logging of events and metrics related to the operation of the game</li>
        </ul>
        <h3>How do we collect your data?</h3>
        <p>
          You directly provide Mod Digital with some of the data we collect. We collect data and process data when you:
        </p>
        <ul>
          <li>
            Register online or place an order for any of our products or services.
          </li>
          <li>
            Voluntarily complete a customer survey or provide feedback on social media or via email.
          </li>
          <li>Use or view the game via your browser’s cookies.</li>
        </ul>
        <p>We also automatically collect event and metric data regarding the game's performance and user activities while playing the game.</p>
        <h3>How will we use your data?</h3>
        <p>Mod Digital collects your data so that we can:</p>
        <ul>
          <li>Provide access to our services and manage your account.</li>
          <li>Email you with information about the services we provide you.</li>
          <li>Monitor the game for performance and availability</li>
          <li>Correct issues impacting the game's availability or playability</li>
        </ul>
        <p>
          When purchasing something from Mod Digital, we may send your data to, and
          also use the resulting information from, credit reference agencies to
          prevent fraudulent purchases.
        </p>
        <h3>How do we store your data?</h3>
        <p>
          Mod Digital securely stores your data in the United States using the Microsoft Cloud. <a href="https://docs.microsoft.com/en-us/azure/compliance/" target="_blank" rel="noreferrer">Azure Compliance Documentation</a>
        </p>
        <p>
          Mod Digital will retain your personal information only for as long
          as is necessary for the purposes set out in this Privacy Policy. We
          will retain and use your information to the extent necessary to deliver our services, comply
          with our legal obligations, resolve disputes, and enforce our
          policies.
        </p>
        <h3>Marketing</h3>
        <p>
          Mod Digital would like to send you information about products and
          services of ours that we think you might like.
        </p>
        <p>
          If you have agreed to receive marketing, you may always opt out at a
          later date.
        </p>
        <p>
          You have the right at any time to stop Mod Digital from contacting you
          for marketing purposes.
        </p>
        <h3>What are your data protection rights?</h3>
        <p>
          Mod Digital would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:
        </p>
        <p>
          <strong>The right to access</strong> – You have the right to request
          Mod Digital for copies of your personal data. We may charge you a
          small fee for this service.
        </p>
        <p>
          <strong>The right to rectification</strong> – You have the right to
          request that Mod Digital correct any information you believe is
          inaccurate. You also have the right to request Mod Digital to complete
          the information you believe is incomplete.
        </p>
        <p>
          <strong>The right to erasure</strong> – You have the right to request
          that Mod Digital erase your personal data, under certain conditions.
        </p>
        <p>
          <strong>The right to restrict processing</strong> – You have the right
          to request that Mod Digital restrict the processing of your personal
          data, under certain conditions.
        </p>
        <p>
          <strong>The right to object to processing</strong> – You have the
          right to object to Mod Digital’s processing of your personal data,
          under certain conditions.
        </p>
        <p>
          <strong>The right to data portability</strong> – You have the right to
          request that Mod Digital transfer the data that we have collected to
          another organization, or directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us via email: <a href="mailto:hello@moddigital.net">hello@moddigital.net</a>
        </p>
        <h3>Cookies</h3>
        <p>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit the game, we may collect information from you automatically
          through cookies or similar technology
        </p>
        <p>For further information, visit <a href="allaboutcookies.org" target="_blank" rel="noreferrer">allaboutcookies.org</a>.</p>
        <h3>How do we use cookies?</h3>
        <p>
          Mod Digital uses cookies in a range of ways to improve your experience
          with the game, including:
        </p>
        <ul>
          <li>Keeping you signed in</li>
          <li>Understanding how you use and play the game</li>
        </ul>
        <h3>What types of cookies do we use?</h3>
        <p>
          There are a number of different types of cookies, however, Words 5X
          uses:
        </p>
        <ul>
          <li>
            Functionality – Mod Digital uses these cookies so that we recognize
            you on the game and remember your previously selected
            preferences. These could include what language you prefer and
            location you are in. A mix of first-party and third-party cookies
            are used.
          </li>
          <li>
            Advertising – Mod Digital uses these cookies to collect information
            about your visit to the game, the content you viewed, the links
            you followed and information about your browser, device, and your IP
            address. Mod Digital sometimes shares some limited aspects of this
            data with third parties for advertising purposes. We may also share
            online data collected through cookies with our advertising partners.
            This means that when you visit another website, you may be shown
            advertising based on your browsing patterns on the game.
          </li>
        </ul>
        <p>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </p>

        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>
        <p>
          Third-party ad servers or ad networks use technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that may appear in the game, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        <p>
          Note that Mod Digital LLC has no access to or control over these cookies that
          are used by third-party advertisers.
        </p>

        <h3>How to manage cookies</h3>
        <p>
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, in a few
          cases, some of the game features may not function as a result.
        </p>
        <h3>Privacy policies of other websites</h3>
        <p>
          Words 5X may contain links to other websites. This privacy
          policy applies only to Words 5X, so if you click on a link to
          another website, you should read their privacy policy.
        </p>
        <h3>Changes to our privacy policy</h3>
        <p>
          Mod Digital keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          18 February 2022.
        </p>
        <h3>How to contact us</h3>
        <p>
          If you have any questions about Mod Digital’s privacy policy, the data
          we hold on you, or you would like to exercise one of your data
          protection rights, please do not hesitate to contact us.
        </p>
        <p>Email us at: <a href="mailto:hello@moddigital.net">hello@moddigital.net</a></p>
        <p>Call us: +1 847 305-1500</p>

      </div>
    );
}