import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "a6f02f7d-ef97-42a8-9b75-546f3a07c6ae",
        authority: "https://modgames.b2clogin.com/modgames.onmicrosoft.com/B2C_1_W5XSignupSignin", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        knownAuthorities: ["modgames.b2clogin.com"],
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                // eslint-disable-next-line default-case
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_W5XSignupSignin"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://modgames.b2clogin.com/modgames.onmicrosoft.com/B2C_1_W5XSignupSignin",
        }
    },
    authorityDomain: "modgames.b2clogin.com"
}

export const apiConfig = {
    b2cScopes: ["https://modgames.onmicrosoft.com/08ec6cc0-5084-4110-96fb-dec88f217375/API.Access"],
//    webApiUri: "https://localhost:7229/"
    webApiUri: "https://api.words5x.com/"
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid","offline_access"]
};

export const tokenRequest = {
    scopes: apiConfig.b2cScopes
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
 export const silentRequest = {
    scopes: ["openid", "profile"]
  };